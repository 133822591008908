var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppMain',{attrs:{"loading":_vm.$apollo.queries.deliveries.loading,"ready":_vm.hasDeliveries},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('AppEmpty',{attrs:{"icon":"dairy","title":"No more deliveries!"}})]},proxy:true}])},[(_vm.hasDeliveries)?_c('AppPanel',{attrs:{"value":0}},_vm._l((_vm.currentDeliveries),function(delivery){return _c('AppPanelItem',{key:delivery.id,attrs:{"title":delivery.location.name,"icon":"dairy"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!delivery.locationChanged)?_c('v-btn',{attrs:{"to":{
            name: 'driver-delivery-change',
            params: { id: delivery.id },
          },"outlined":""}},[_vm._v(" Change Dairy ")]):_vm._e(),_c('v-btn',{attrs:{"to":{
            name: 'driver-delivery-complete',
            params: { id: delivery.id },
          },"color":"accent"}},[_vm._v(" Complete Delivery ")])]},proxy:true}],null,true)},[_c('AppListDelivery',{attrs:{"delivery":delivery}}),(delivery.route)?_c('AppListItem',{attrs:{"icon":"mdi-link"}},[_c('div',[_c('v-list-item-subtitle',[_vm._v("Related Route")]),_vm._v(" "+_vm._s(delivery.route.name)+" ")],1),(delivery.route.driver)?_c('div',{staticClass:"mt-2"},[_c('v-list-item-subtitle',[_vm._v("Driver")]),_vm._v(" "+_vm._s(delivery.route.driver.fullName)+" ")],1):_vm._e(),(delivery.route.trailer)?_c('div',{staticClass:"mt-2"},[_c('v-list-item-subtitle',[_vm._v("Trailer")]),_vm._v(" "+_vm._s(delivery.route.trailer.name)+" ")],1):_vm._e()]):_vm._e(),(delivery.locationChanged)?_c('AppListItem',{attrs:{"icon":"mdi-map"}},[_c('v-row',{staticClass:"w-full",attrs:{"align":"center","no-gutters":""}},[_c('v-col',[(delivery.originalLocation)?_c('div',[_c('v-list-item-subtitle',[_vm._v("Original Location")]),_vm._v(" "+_vm._s(delivery.originalLocation.name)+" ")],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('v-list-item-subtitle',[_vm._v("Reason For Removal")]),_vm._v(" "+_vm._s(delivery.changeReason)+" ")],1),_c('div',{staticClass:"mt-2"},[_c('v-list-item-subtitle',[_vm._v("Notes")]),_vm._v(" "+_vm._s(delivery.changeNotes)+" ")],1)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var listeners = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"grey","dark":"","icon":""},on:{"click":function($event){return _vm.undoDeliveryChange(delivery.id)}}},listeners),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo Delivery Change")])])],1)],1):_vm._e()],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }