



























































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListDelivery from '@/components/AppListDelivery.vue';
import AppListItem from '@/components/AppListItem.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import DELIVERIES from '@/graphql/queries/Deliveries.graphql';
import DELIVERY_UNDO from '@/graphql/mutations/DeliveryChangeUndo.graphql';
import { DELIVERY_STATUSES } from '@/utils/constants';
import { DeliveriesQuery } from '@/types/schema';

export default Vue.extend({
  name: 'DriverDelivery',
  components: {
    AppMain,
    AppPanel,
    AppPanelItem,
    AppListDelivery,
    AppListItem,
    AppEmpty,
  },
  data() {
    return {
      deliveries: [] as DeliveriesQuery['deliveries'],
    };
  },
  computed: {
    hasDeliveries(): boolean {
      return this.currentDeliveries.length > 0;
    },
    currentDeliveries(): DeliveriesQuery['deliveries'] {
      const deliveries = this.deliveries.filter(
        (delivery) => delivery.status === DELIVERY_STATUSES.INCOMPLETE
      );
      return deliveries;
    },
  },
  methods: {
    async undoDeliveryChange(id: string) {
      await this.$apollo.mutate({
        mutation: DELIVERY_UNDO,
        variables: {
          input: {
            id,
          },
        },
      });
      this.$notify({
        text: 'Delivery change reverted!',
        type: 'success',
        duration: 6000,
      });
      this.$apollo.queries.deliveries.refetch();
    },
  },
  apollo: {
    deliveries: {
      query: DELIVERIES,
      fetchPolicy: 'cache-and-network',
    },
  },
});
